import { AppScreen } from "@stackflow/plugin-basic-ui";
import type { ActivityComponentType } from "@stackflow/react";
import React, { useState } from "react";

import * as css from "./index.css";
import { flexRow, flexColumn } from "../../styles/f.css";
import { style } from "@vanilla-extract/css";
import ColoredText from "./ColoredText";
import { vars } from "@seed-design/design-token";
import {
  APP_SCHEME_PREFIX,
  IS_PROD,
  IS_ALPHA,
  isDarkMode,
} from "../../env/config";
import { useEffect } from "react";
import { fleaMarketAPI } from "../../apis/FleaMarketAPI";
import { xpropertyApi } from "../../apis/Xproperty";
import { useTranslation } from "react-i18next";
import { BRIDGE_INFO } from "../../env/bridgeInfo";
import fastSellingItemsMock from "../../mocks/fastSellingItems.json";
import xpropertyMock from "../../mocks/xpropertyMock.json";
import { bridge } from "../../env/bridge";
import { LoadingPage } from "../../components/LoadingPage";

type FastSellingItemType = {
  title: string;
  thumbnail_url: string;
  sold_minute: number;
  price_description: string;
  target_uri: string;
};

type FastSellingItemsApiResType = {
  fast_selling_items: FastSellingItemType[];
  location_name: string;
  sellers_count: number;
};

const isIOS = /iphone|ipad|ipod/i.test(
  window.navigator.userAgent.toLowerCase(),
);

const FastSellingItems: ActivityComponentType = () => {
  const [data, setData] = useState<FastSellingItemsApiResType | null>(null);
  const [brandImages, setBrandImages] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      if (IS_PROD || IS_ALPHA) {
        const [fleaRes, xpropertyRes] = await Promise.all([
          fleaMarketAPI.fastSellingItems(),
          xpropertyApi.getProperty(),
        ]);

        setData(fleaRes?.data);

        const brandImagesRes =
          xpropertyRes?.data?.properties?.fleamarket_fast_selling_items_webview
            .brands_images[BRIDGE_INFO.COUNTRY_CODE.toLocaleLowerCase()] || [];

        setBrandImages(brandImagesRes.slice(0, 9));
      } else {
        // 로컬 개발시 mock json 활용
        setData(fastSellingItemsMock);
        setBrandImages(
          // @ts-ignore
          xpropertyMock.brands_images[
            BRIDGE_INFO.COUNTRY_CODE.toLocaleLowerCase()
          ],
        );
      }
    };

    fetch();
  }, [fleaMarketAPI, xpropertyApi]);

  const onSellClick = ({
    position,
  }: {
    position: "soldItemSection" | "brandSection" | "footerSection";
  }) => {
    bridge.logEvent({
      analytics: {
        target: "KARROT",
        name: `client_clicked_fleamarket_fastSellingItems_${position}_sellNowButton_v1.json`,
        params: JSON.stringify({}),
      },
    });

    window.location.href = `${APP_SCHEME_PREFIX}fleamarket_articles/new`;
  };

  const onHomeClick = () => {
    window.location.href = `${APP_SCHEME_PREFIX}tab/home`;
  };

  if (!data) return <LoadingPage />;

  const items = data?.fast_selling_items;
  const hasItems = Array.isArray(items) && items.length > 0;

  let avatarGroupImagePath = "";

  if (BRIDGE_INFO.COUNTRY_CODE === "JP") {
    avatarGroupImagePath = isDarkMode
      ? "https://dnvefa72aowie.cloudfront.net/origin/etc/202402/43a789f59a2c2409e8968c064a6b4fb06d4079cae5a40a65b8f9d0fb28a4fb7c.png"
      : "https://dnvefa72aowie.cloudfront.net/origin/etc/202402/dfbc1acdf037af1ddc3858944626548ac48f42458a53839987ddf98188be8349.png";
  } else {
    avatarGroupImagePath = isDarkMode
      ? "https://dnvefa72aowie.cloudfront.net/origin/etc/202402/eef5b70f7c19296131cde6aac281c7d6f1804f6876e427428ff03e74b514716e.png"
      : "https://dnvefa72aowie.cloudfront.net/origin/etc/202402/2186ebd26b801c7d09e1ba173f10168dbc461555967c8fe110c2df8665418ee4.png";
  }

  const getMainItemSoldText = ({ soldMinute }: { soldMinute: number }) => {
    if (!hasItems) return "";

    if (soldMinute / 60 < 1) {
      return t("fast_selling_items.sold_text_min", {
        sold_minute: soldMinute,
      });
    } else if (soldMinute / 60 < 2) {
      return t("fast_selling_items.sold_text_hour", {
        sold_hour: Math.floor(soldMinute / 60),
      });
    } else {
      return t("fast_selling_items.sold_text_hours", {
        sold_hours: Math.floor(soldMinute / 60),
      });
    }
  };
  const getSubItemSoldText = ({ soldMinute }: { soldMinute: number }) => {
    if (!hasItems) return "";

    const soldHours = soldMinute / 60;

    if (soldHours < 1) {
      return t("fast_selling_items.sold_text_min", {
        sold_minute: `<color>${soldMinute}<color>`,
      });
    } else if (soldHours < 2) {
      return t("fast_selling_items.sold_text_hour", {
        sold_hour: `<color>${Math.floor(soldMinute / 60)}<color>`,
      });
    } else if (soldHours < 24) {
      return t("fast_selling_items.sold_text_hours", {
        sold_hours: `<color>${Math.floor(soldMinute / 60)}<color>`,
      });
    } else if (soldHours < 144) {
      const soldDays = Math.ceil(soldHours / 24);
      return t("fast_selling_items.sold_text_days", {
        days: `<color>${soldDays}<color>`,
      });
    } else {
      return t("fast_selling_items.sold_this_week");
    }
  };

  const isJapaneseLng = /^ja(-|_|$)/i.test(BRIDGE_INFO.ACCEPT_LANGUAGE);

  return (
    <AppScreen
      appBar={{
        title: t("fast_selling_items.fast_sellers"),
        border: false,
      }}
    >
      <section className={css.wrap}>
        <section className={css.container}>
          <div className={css.title}>
            <ParseBreakLineInText text={t("fast_selling_items.title")} />
          </div>
          {hasItems && (
            <section>
              <div className={css.subtitle}>
                {t("fast_selling_items.subtitle")}
              </div>
              {/* 첫 번째 아이템만 다른 강조된 디자인으로 보여줘요. */}
              <div
                className={css.mainItem}
                onClick={() => {
                  window.location.href = items[0].target_uri;
                }}
              >
                <div className={css.mainItemFlexColumn}>
                  <div>
                    <span className={css.mainItemChip}>
                      {getMainItemSoldText({
                        soldMinute: items[0].sold_minute,
                      })}
                    </span>
                  </div>
                  <div className={css.mainItemTitle}>{items[0].title}</div>
                  <div className={css.mainItemPrice}>
                    {items[0].price_description}
                  </div>
                </div>
                <div className={css.thumbnailContainer}>
                  <img
                    className={css.thumbnail}
                    src={items[0].thumbnail_url}
                    loading="lazy"
                  />
                </div>
              </div>
              {items
                .slice(1, 11)
                .map((item: FastSellingItemType, index: number) => {
                  const isLastItem = index === items.slice(1, 11).length - 1;

                  return (
                    <div key={index}>
                      <div
                        className={css.subItem}
                        onClick={() => {
                          window.location.href = item.target_uri;
                        }}
                      >
                        <div className={css.subItemFlexColumn}>
                          <div>
                            <div className={css.subItemSoldText}>
                              <ColoredText
                                text={getSubItemSoldText({
                                  soldMinute: item.sold_minute,
                                })}
                              />
                            </div>
                          </div>
                          <div>
                            <div className={css.subItemTitle}>{item.title}</div>
                          </div>
                          <div>
                            <div className={css.subItemPrice}>
                              {item.price_description}
                            </div>
                          </div>
                        </div>
                        <img
                          src={item.thumbnail_url}
                          className={css.subItemThumbnail}
                          loading="lazy"
                        />
                      </div>
                      {!isLastItem && (
                        // 아이템 사이에 줄을 하나씩 그어줘요.
                        <div className={css.subItemFlexUnderLine} />
                      )}
                    </div>
                  );
                })}
              <div
                className={css.sellButton}
                style={{ marginTop: "10px", marginBottom: "60px" }}
                onClick={() => onSellClick({ position: "soldItemSection" })}
              >
                {t("fast_selling_items.sell_yours_now")}
              </div>
            </section>
          )}
          <section>
            <div className={css.brandSectionTitle}>
              {t("fast_selling_items.top_searched_brand")}
            </div>
            <section className={css.gridBrands}>
              {brandImages.length > 0 &&
                brandImages.map((imageUrl, index) => (
                  <div className={css.gridImageContainer} key={index}>
                    <img
                      className={css.gridBrandImage}
                      src={imageUrl}
                      alt="Image 1"
                      loading="lazy"
                    />
                  </div>
                ))}
              {brandImages.length === 0 &&
                new Array(9).fill(null).map((e, index) => (
                  // api 호출 전 빈 이미지 컨테이너 보여주기
                  <div className={css.gridImageContainer} key={index}>
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        aspectRatio: "1/1",
                      }}
                    />
                  </div>
                ))}
            </section>
            <div
              className={css.sellButton}
              style={{ marginTop: "12px", marginBottom: "60px" }}
              onClick={() => onSellClick({ position: "brandSection" })}
            >
              {t("fast_selling_items.sell_yours_now")}
            </div>
          </section>
          <section className={css.exampleArticleSection}>
            <div className={css.title2}>
              <ParseBreakLineInText
                text={t("fast_selling_items.this_week_text", {
                  location_name: data.location_name,
                  sellers_count:
                    data.sellers_count < 1000
                      ? data.sellers_count
                      : `${Math.round(data.sellers_count / 1000)}${
                          isJapaneseLng ? "千" : "K"
                        }`,
                })}
              />
            </div>
            <div className={css.flexJustifyCenter}>
              <img
                className={css.avatarGroupImage}
                src={avatarGroupImagePath}
                loading="lazy"
              />
            </div>
            <ExampleArticleBanner />
          </section>
        </section>
        <div className={css.lastUnderLine} />
        <div
          className={css.sellNowButton}
          onClick={() => onSellClick({ position: "footerSection" })}
        >
          {t("fast_selling_items.sell_now")}
        </div>
        <div className={css.flexJustifyCenter}>
          <span className={css.homeFeedButton} onClick={onHomeClick}>
            {t("fast_selling_items.go_to_home_feed")}
          </span>
        </div>
      </section>
    </AppScreen>
  );
};

const ExampleArticleBanner = () => {
  const isJP = BRIDGE_INFO.COUNTRY_CODE === "JP";

  return (
    <section className={css.exampleArticleBanner}>
      <div className={css.exampleArticleMainItem}>
        <img
          className={css.exampleArticleImage}
          src={"/fastSellingItems/example_article.png"}
          loading="lazy"
        />
        <div className={flexColumn} style={{ width: "100%" }}>
          <div className={css.exampleItemTitle}>
            {isJP ? "2人掛けソファ" : "Cream couch"}
          </div>
          <div className={css.exampleItemSubtext}>
            {isJP ? "新宿区 西新宿 ⸱ 30分前" : "Bay Street Corridor ⸱ 30m"}
          </div>
          <div className={css.exampleItemPrice}>
            {isJP ? "15,000円" : "$150"}
          </div>
          <div className={css.exmapleITemMetadata}>
            <ChatIcon />3
            <div style={{ marginRight: "2px" }} />
            <LikeIcon />6
          </div>
        </div>
      </div>
      <div className={css.grayItemsContainer}>
        {new Array(2).fill(null).map((e, i) => {
          return (
            <div className={css.grayItem} key={i}>
              <div className={css.grayThumbnail} />
              <div className={css.grayMetadataContainer}>
                <div className={css.grayMetadataItem({ width: "short" })}>
                  &nbsp;
                </div>
                <div className={css.grayMetadataItem({ width: "long" })}>
                  &nbsp;
                </div>
                <div className={css.grayMetadataItem({ width: "long" })}>
                  &nbsp;
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const ChatIcon = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={vars.$scale.color.gray600}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_37204_14698)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.399963 3.1666C0.399963 1.80067 1.77737 0.766602 3.3333 0.766602C4.20572 0.766602 5.02202 1.09171 5.57085 1.61814C6.69531 1.91401 7.59998 2.78497 7.59998 3.90004C7.59998 4.7322 7.10108 5.51103 6.35183 5.95043C6.48451 6.21454 6.60211 6.40421 6.69025 6.53185C6.74612 6.61277 6.79022 6.66887 6.8189 6.70328L6.85635 6.74596C6.93283 6.82338 6.95463 6.93958 6.91128 7.03951C6.8677 7.13994 6.76717 7.20354 6.65776 7.19989L6.65667 7.19985L6.59752 7.19575C6.56283 7.19259 6.51411 7.18708 6.45337 7.17754C6.33196 7.15846 6.16205 7.12317 5.95986 7.05802C5.5745 6.93385 5.07134 6.70096 4.56454 6.26535C4.00232 6.25038 3.48571 6.1117 3.03862 5.86642C2.67059 6.11192 2.32067 6.25713 2.03492 6.34285C1.83187 6.40377 1.6612 6.43467 1.53918 6.45036C1.47813 6.45821 1.42913 6.46227 1.39419 6.46437L1.3333 6.4666C1.22258 6.4666 1.12338 6.39819 1.08404 6.2947C1.04504 6.19212 1.07289 6.07623 1.15397 6.00257C1.15453 6.00203 1.15601 6.00062 1.15834 5.99829C1.16359 5.99304 1.17321 5.98312 1.18674 5.96791C1.2138 5.93752 1.25659 5.88596 1.31142 5.80844C1.39891 5.68474 1.51724 5.49471 1.65129 5.21886C0.90027 4.7798 0.399963 3.99994 0.399963 3.1666ZM3.63348 5.55374C3.94478 5.67024 4.2914 5.73337 4.66664 5.73337C4.73252 5.73337 4.79607 5.75776 4.84503 5.80183C5.28379 6.19671 5.72052 6.41157 6.05798 6.52851C5.96614 6.37052 5.86427 6.17731 5.75756 5.94448C5.6962 5.8106 5.75498 5.65232 5.88887 5.59095C6.60764 5.26152 7.06664 4.58164 7.06664 3.90004C7.06664 3.30995 6.70466 2.7647 6.12138 2.418C6.21534 2.65181 6.26663 2.90303 6.26663 3.1666C6.26663 4.44393 5.06211 5.43106 3.63348 5.55374ZM1.93942 5.81405C2.03272 5.65289 2.13603 5.4529 2.24408 5.20732C2.30275 5.07397 2.24355 4.91821 2.1111 4.85751C1.39233 4.52807 0.933329 3.84819 0.933329 3.16659C0.933329 2.19919 1.95592 1.29993 3.33333 1.29993C4.71074 1.29993 5.73333 2.19919 5.73333 3.16659C5.73333 4.134 4.71074 5.03326 3.33333 5.03326C3.26745 5.03326 3.20391 5.05764 3.15494 5.10172C2.71428 5.4983 2.27611 5.70551 1.93942 5.81405Z"
          fill="current"
        />
      </g>
      <defs>
        <clipPath id="clip0_37204_14698">
          <rect width="8" height="8" fill="current" />
        </clipPath>
      </defs>
    </svg>
  );
};

const LikeIcon = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={vars.$scale.color.gray600}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.932439 1.60246C1.70394 0.799179 2.96903 0.799179 3.74053 1.60246L3.74081 1.60276L3.99576 1.86901L4.25098 1.60246C5.02248 0.799179 6.28757 0.799179 7.05907 1.60246L7.0597 1.60312C7.84142 2.42258 7.71062 3.651 7.07878 4.47948L7.07837 4.48002C6.44225 5.30974 5.70732 5.97864 5.13399 6.43898C4.84676 6.66961 4.59869 6.84901 4.42178 6.97115C4.33329 7.03225 4.26251 7.07909 4.21342 7.11092C4.18888 7.12684 4.16975 7.139 4.15653 7.14733L4.1412 7.15693L4.13697 7.15954L4.13574 7.1603L4.13535 7.16054C4.13535 7.16054 4.1351 7.16069 3.99576 6.93333C3.86044 7.16312 3.86033 7.16305 3.86033 7.16305L3.85977 7.16272L3.85848 7.16196L3.85406 7.15933L3.83808 7.1497C3.82432 7.14135 3.80443 7.12916 3.77895 7.11321C3.72801 7.08133 3.6547 7.03442 3.56341 6.97323C3.3809 6.85091 3.12604 6.67123 2.83402 6.44017C2.2515 5.97927 1.51378 5.30821 0.90891 4.47435L0.908574 4.47388C0.310023 3.64499 0.14607 2.42123 0.932439 1.60246ZM3.99576 6.93333L3.86033 7.16305C3.9453 7.21308 4.05128 7.21207 4.13535 7.16054L3.99576 6.93333ZM3.99316 6.61748C4.02888 6.5937 4.07099 6.56525 4.11875 6.53227C4.28673 6.41629 4.5243 6.24455 4.80008 6.02312C5.3527 5.5794 6.05315 4.94063 6.65488 4.15583C7.17498 3.47367 7.22512 2.54957 6.67412 1.97159C6.1125 1.38718 5.19727 1.38725 4.63574 1.9718L4.18836 2.43901C4.13806 2.49155 4.06849 2.52125 3.99576 2.52125C3.92302 2.52125 3.85345 2.49155 3.80315 2.43901L3.35588 1.9719C2.79425 1.38722 1.87869 1.38717 1.31709 1.9719C0.770392 2.54113 0.834905 3.46068 1.3408 4.16143C1.90715 4.94212 2.60484 5.57876 3.16494 6.02192C3.44427 6.24293 3.68754 6.41439 3.86033 6.5302C3.911 6.56415 3.95557 6.5933 3.99316 6.61748Z"
        fill="current"
      />
    </svg>
  );
};

const ParseBreakLineInText = ({ text }: { text: string }) => {
  return (
    <>
      {text.split("<br/>").map((text, index) => {
        return (
          <div key={index}>
            <ColoredText text={text} />
          </div>
        );
      })}
    </>
  );
};

export default FastSellingItems;
