import { AppScreen } from "@stackflow/plugin-basic-ui";
import "@daangn/sprout-components-spinner/index.css";

import { Spinner } from "@daangn/sprout-components-spinner";
import { style } from "@vanilla-extract/css";

// 로딩 스피너 돌아가는 페이지
export const LoadingPage = () => {
  return (
    <AppScreen
      appBar={{
        border: false,
      }}
    >
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "160px",
          boxSizing: "border-box",
        }}
      >
        <Spinner />
      </div>
    </AppScreen>
  );
};
