import * as css from "./index.css";

/**
 * 텍스트 중간에 색을 바꿀 부분을, <color>사이에 넣어서 보내줘요.
 */

type ColoredTextProps = {
  text: string;
  color?: "primary";
};

const ColoredText = ({ text, color = "primary" }: ColoredTextProps) => {
  const arr = text.split("<color>");

  return (
    <span>
      {arr?.map((str, index) => {
        const isColored = index % 2 === 1;

        if (isColored) {
          return (
            <span key={index} className={css.colorText({ color })}>
              {str}
            </span>
          );
        }

        return <span key={index}>{str}</span>;
      })}
    </span>
  );
};

export default ColoredText;
