import { vars } from "@seed-design/design-token";
import { basicUIPlugin, IconBack } from "@stackflow/plugin-basic-ui";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { karrotAnalyticsPlugin } from "@daangn/stackflow-plugin-karrot-analytics";
import { stackflow } from "@stackflow/react";
import React from "react";

import FastSellingItems from "../activities/fastSellingItems/index";
import { bridge } from "../env/bridge";

const theme = /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())
  ? "cupertino"
  : "android";

const { Stack, useFlow } = stackflow({
  transitionDuration: 270,
  activities: {
    ActivityHome: React.lazy(() => import("../activities/ActivityHome")),
    FastSellingItems,
    ActivityNotFound: React.lazy(
      () => import("../activities/ActivityNotFound"),
    ),
  },
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor:
          theme === "cupertino"
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
        closeButton: {
          renderIcon: () => <IconBack />,
          onClick() {
            bridge.closeRouter({});
          },
        },
      },
    }),
    historySyncPlugin({
      routes: {
        ActivityHome: "/",
        FastSellingItems: "/pages/main",
        ActivityNotFound: "/404",
      },
      fallbackActivity: () => "ActivityNotFound",
    }),
    karrotAnalyticsPlugin({
      bridge,
      serviceName: "fleamarket",
    }),
  ],
});

export { Stack };
export type TypeUseFlow = typeof useFlow;
