import Request from "./Request";

class FleaMarketAPI extends Request {
  constructor() {
    super();
    this.setFleaMarketRequestConfig();
  }

  fastSellingItems() {
    const url = "api/v24/articles/fast_selling_items.json";

    return this.req.get(url);
  }
}

export const fleaMarketAPI = new FleaMarketAPI();
