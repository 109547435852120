import { getCurrentEnvironment } from "@daangn/karrotbridge/lib/core/getCurrentEnvironment";

// 카카오 인앱브라우저
export const isKakaoInAppBrowser = /kakaotalk/i.test(
  window.navigator.userAgent.toLowerCase(),
);

// 웹 환경
export const IS_WEB = getCurrentEnvironment() === "Web" || isKakaoInAppBrowser;

export const APP_ENV = import.meta.env.VITE_APP_ENV;
export const IS_PROD = APP_ENV === "production";
export const IS_ALPHA = APP_ENV === "alpha";
export const IS_DEV = APP_ENV === "dev";
export const APP_SCHEME_PREFIX = IS_PROD ? `karrot://` : `karrot.alpha://`;

export const isDarkMode = window.matchMedia(
  "(prefers-color-scheme: dark)",
).matches;
