import Request, { getApiCountryCode } from "./Request";

class XpropertyAPI extends Request {
  constructor() {
    super();
    this.setXpropertyRequestConfig();
  }

  getProperty() {
    const url = `/v1/countries/${getApiCountryCode()}/properties`;

    return this.req.get(url);
  }
}

export const xpropertyApi = new XpropertyAPI();
