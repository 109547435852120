import { useEffect, useState } from "react";

import i18n from "../i18n/i18n";
import { bridge } from "./bridge";
import { IS_ALPHA, IS_DEV, IS_PROD } from "./config";

type CountryCodeType = "KR" | "CA" | "JP" | "GB" | "US";

type BridgeInfoType = {
  AUTH_TOKEN: string;
  ACCEPT_LANGUAGE: string;
  COUNTRY_CODE: CountryCodeType;
  DEVICE_IDENTITY: string;
  REGION_ID: number;
  USER_AGENT: string;
  USER_ID: number;
};

export const BRIDGE_INFO: BridgeInfoType = {
  AUTH_TOKEN: "",
  ACCEPT_LANGUAGE: "",
  COUNTRY_CODE: "CA",
  DEVICE_IDENTITY: "",
  REGION_ID: -1,
  USER_AGENT: "",
  USER_ID: -1,
};

export const useBridgeInfoInit = () => {
  const [isBridgeInit, setIsBridgeInit] = useState<boolean>(false);

  useEffect(() => {
    if (IS_DEV) {
      /* 로컬 개발 환경 */
      BRIDGE_INFO.AUTH_TOKEN = import.meta.env.VITE_AUTH_TOKEN;
      BRIDGE_INFO.ACCEPT_LANGUAGE = import.meta.env.VITE_ACCEPT_LANGUAGE;
      BRIDGE_INFO.COUNTRY_CODE = import.meta.env.VITE_COUNTRY_CODE;
      BRIDGE_INFO.DEVICE_IDENTITY = import.meta.env.VITE_DEVICE_IDENTITY;
      BRIDGE_INFO.REGION_ID = import.meta.env.VITE_REGION_ID;
      BRIDGE_INFO.USER_AGENT = import.meta.env.VITE_USER_AGENT;
      BRIDGE_INFO.USER_ID = import.meta.env.VITE_USER_ID;

      Object.freeze(BRIDGE_INFO);
      i18n.changeLanguage(BRIDGE_INFO.ACCEPT_LANGUAGE);
      setIsBridgeInit(true);
    } else if (IS_PROD || IS_ALPHA) {
      /* 인앱 웹뷰 환경 */
      (async () => {
        const [appRes, userRes, regionRes] = await Promise.all([
          bridge.getAppInfo({}),
          bridge.getUserInfo({}),
          bridge.getRegionInfo({}),
        ]);

        if (!appRes || !userRes || !regionRes) {
          const errorMessage = JSON.stringify({
            appRes,
            userRes,
            regionRes,
          });

          throw new Error(`bridge.getInfo is not working : ${errorMessage}`);
        }

        BRIDGE_INFO.AUTH_TOKEN = userRes.info.user.authToken;
        BRIDGE_INFO.ACCEPT_LANGUAGE = appRes.info.app.locale;
        BRIDGE_INFO.COUNTRY_CODE = appRes.info.app.country;
        BRIDGE_INFO.DEVICE_IDENTITY = appRes.info.app.deviceIdentity;
        BRIDGE_INFO.REGION_ID = regionRes.info.region.id;
        BRIDGE_INFO.USER_AGENT = appRes.info.app.userAgent;
        BRIDGE_INFO.USER_ID = userRes.info.user.id;

        Object.freeze(BRIDGE_INFO);
        i18n.changeLanguage(BRIDGE_INFO.ACCEPT_LANGUAGE);
        setIsBridgeInit(true);
      })();
    }
  }, []);

  return { isBridgeInit };
};
