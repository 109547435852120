import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useEffect } from "react";
import { IS_PROD } from "./env/config";

import VConsole from "vconsole";
import { BridgeProvider } from "./env/bridge";
import { useBridgeInfoInit } from "./env/bridgeInfo";
import { Stack } from "./stackflow";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    let vConsole: any = null;
    if (!IS_PROD) {
      vConsole = new VConsole({ theme: "dark" });
    }
    return () => {
      if (!vConsole) return;
      vConsole.destroy();
    };
  }, []);

  const { isBridgeInit } = useBridgeInfoInit();

  return (
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BridgeProvider>
          <Suspense>{isBridgeInit && <Stack />}</Suspense>
        </BridgeProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
