import type { AxiosInstance } from "axios";
import axios from "axios";

import { IS_PROD } from "../env/config";
import { BRIDGE_INFO } from "../env/bridgeInfo";
import { bridge } from "../env/bridge";

export const getApiCountryCode = () => {
  let apiCountryCode = BRIDGE_INFO.COUNTRY_CODE.toLocaleLowerCase();

  if (BRIDGE_INFO.COUNTRY_CODE === "US") {
    apiCountryCode = "ca";
  } else if (BRIDGE_INFO.COUNTRY_CODE === "GB") {
    apiCountryCode = "uk";
  }

  return apiCountryCode;
};

export default class Request {
  public req: AxiosInstance;

  constructor() {
    this.req = axios.create({
      baseURL: "",
      headers: {},
    });

    this.req.interceptors.response.use(
      (res) => res,
      infraMaintenanceRedirectMiddleware,
    );
  }

  setFleaMarketRequestConfig() {
    this.req.interceptors.request.use(async (config: any) => {
      if (!config.headers) {
        return config;
      }
      config.baseURL = `https://webapp${
        IS_PROD ? "" : ".alpha"
      }.${getApiCountryCode()}.karrotmarket.com`;

      config.headers["accept-language"] = BRIDGE_INFO.ACCEPT_LANGUAGE;
      config.headers["X-AUTH-TOKEN"] = BRIDGE_INFO.AUTH_TOKEN;
      config.headers["X-USER-AGENT"] = BRIDGE_INFO.USER_AGENT;

      return config;
    });
    this.req.interceptors.response.use(fleaMarket200ErrorHandlingMiddleware);
  }

  setXpropertyRequestConfig() {
    this.req.interceptors.request.use(async (config: any) => {
      if (!config.headers) {
        return config;
      }

      config.baseURL = `https://xproperty${
        IS_PROD ? "" : ".alpha"
      }.${getApiCountryCode()}.karrotmarket.com`;

      config.headers["accept-language"] = BRIDGE_INFO.ACCEPT_LANGUAGE;
      config.headers["X-AUTH-TOKEN"] = BRIDGE_INFO.AUTH_TOKEN;
      config.headers["X-USER-AGENT"] = BRIDGE_INFO.USER_AGENT;

      return config;
    });
  }
}

const fleaMarket200ErrorHandlingMiddleware = (res: any) => {
  // 중고거래 서버에서 200코드로 보낸 에러 응답을 throw 처리 해줘요.
  if (
    typeof res?.status === "number" &&
    res.status >= 200 &&
    res.status < 300 &&
    !!res?.data?.status?.message
  ) {
    throw new Error(JSON.stringify(res.data.status));
  }

  return res;
};

const infraMaintenanceRedirectMiddleware = (error: any) => {
  if (error?.response?.status === 599) {
    const errorText =
      error?.response?.data?.status?.message || "Service under maintenance";

    const APP_SCHEME = `karrot${
      IS_PROD ? "" : ".alpha"
    }://minikarrot/router?remote=https%3A%2F%2Fflea-fallback${
      IS_PROD ? "" : ".alpha"
    }.karrotwebview.com%2Fpages%2Fmaintenance%2Findex.html%23%2F%3Fdescription%3D${encodeURIComponent(
      errorText,
    )}&navbar=true&scrollable=false`;

    window.location.href = APP_SCHEME;
    bridge.closeRouter({});
  }

  // 오류를 다시 던져 다음 catch 블록에서 처리할 수 있게 합니다.
  return Promise.reject(error);
};
