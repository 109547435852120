/**
 * 참고:
 * 공용으로 사용되는 CSS를 index.ts에 포함시켜서,
 * Code Splitting으로 인한 과도한 번들 사이즈 증가를 막습니다.
 */
import "normalize.css";
import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "./styles";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import * as Sentry from "@sentry/react";
import { IS_PROD, IS_ALPHA } from "./env/config";

if (IS_PROD || IS_ALPHA) {
  Sentry.init({
    dsn: IS_PROD
      ? "https://0c0c65d2f09342f3e897eda48cb71542@o24217.ingest.sentry.io/4506704762437632"
      : "https://044373a2271ffcbd56186d304596f538@o24217.ingest.sentry.io/4506704780197888",
    tracesSampleRate: 0.0,
  });
}

/**
 * React 18와 Concurrent Rendering을 사용합니다.
 * Stackflow는 startTransition을 적극적으로 활용하고 있습니다.
 * Preloading 등의 테크닉을 통해 JavaScript 코드와 API 콜을 동시에 수행하세요.
 */
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);
